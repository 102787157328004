import {
  CollectionBanner as CollectionBannerType,
  ContentTypeEnum,
  SlideSimple,
} from "@/api/generatedTypes";
import { useFilterHandlers } from "@/components/filter/useFilterHandlers";

import styles from "./CollectionBanner.module.scss";
import { BaseCarousel } from "@/components/BaseCarousel";

interface CollectionBannerProps {
  banners: CollectionBannerType[];
}

const CollectionBanner = ({ banners }: CollectionBannerProps) => {
  const { applyCollections } = useFilterHandlers();

  const slides: SlideSimple[] = banners.map((banner) => {
    const onClick = () => {
      applyCollections([banner.collection_id]);
    };

    return {
      id: banner.collection_id,
      url: banner.banner,
      content_type: banner.banner.includes(".mp4")
        ? ContentTypeEnum.VIDEO
        : ContentTypeEnum.PHOTO,
      onClick: onClick,
    };
  });

  return (
    <div className={styles.banner}>
      <BaseCarousel slides={slides} autoplay={true} />
    </div>
  );
};

export default CollectionBanner;
